$.fn.hasAttr = function(name) {
	return this.attr(name) !== undefined;
};
window.openErrorToast = function(msg) {
	$.toast({
		text: msg.replace('Contract', 'Schedule'),
		hideAfter: false,
		showHideTransition : 'slide',
		bgColor: '#ff0000'
	});
}
window.openForbiddenErrorToast = function() {
	$.toast({
		text: "You have attempted to access a forbidden resource",
		hideAfter: false,
		showHideTransition : 'slide',
		bgColor: '#ff0000'
	});
}

window.openNoticeToast = function(msg) {
	$.toast({
		text: msg.replace('Contract', 'Schedule'),
		loader: true,
		hideAfter: 5000,
		allowToastClose: true,
		showHideTransition : 'slide',
		bgColor: 'green',
		beforeShown: function () {
			$("#feedback_error").hide();
		},
		beforeHidden: function () {
			$("#feedback_error").hide();
		},
		afterShown: function () {
			$("#feedback_error").hide();
		},
		afterHidden: function () {
			$("#feedback_error").hide();
		}
	});
}

window.openFormType = function (evt, formIdentifier) {
	// Declare all variables
	var i, tabcontent, tablinks;

	// Get all elements with class="tabcontent" and hide them
	tabcontent = document.getElementsByClassName("tabcontent");
	for (i = 0; i < tabcontent.length; i++) {
		tabcontent[i].style.display = "none";
	}

	// Get all elements with class="tablinks" and remove the class "active"
	tablinks = document.getElementsByClassName("tablinks");
	for (i = 0; i < tablinks.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(" active", "");
	}

	// Show the current tab, and add an "active" class to the button that opened the tab
	// document.getElementById(formName).style.display = "block";
	// evt.currentTarget.className += " active";
	evt.target.className += " active";

	// reload the filter panel and the submissions table
	var d = {};
	d['authenticity_token'] = window._token;
	d['form_identifier'] = formIdentifier;
	$.ajax({
		type: "GET",
		url: 'home/filters',
		data: d,
	});
}

window.loadAdminScreen = function (type, btn) {
	$("#feedback_error").hide();
	clearToastNotifications();
	if (btn != null) {
		$('.tablinks').each(function (i, obj) {
			$(obj).removeClass('active');
		});
		$(btn).addClass('active');
	}
	// load the admin filters for this selection
	var d = {};
	d['authenticity_token'] = window._token;
	d['admin_area'] = type;
	$.ajax({
		type: "GET",
		url: 'admin/filters',
		data: d
	});

}

window.loadReportingScreen = function (type, btn) {
	$("#feedback_error").hide();
	clearToastNotifications();
	if (btn != null) {
		$('.tablinks').each(function (i, obj) {
			$(obj).removeClass('active');
		});
		$(btn).addClass('active');
	}
	// load the admin filters for this selection

	var d = {};
	d['authenticity_token'] = window._token;
	d['admin_area'] = type;
	$.ajax({
		type: "GET",
		url: 'reporting/filters',
		data: d,
	});
}

// toggle between edit icon and save/cancel icon
window.toggleAdminInlineEdit = function (row, doEdit = false) {
	$(row).find('.submit-button-group').toggle();
	$(row).find('.edit-button-group').toggle();

	$('.active-inline-edit').removeClass('active-inline-edit'); // disable any previous inline-edit rows
	if (doEdit) {
		$(row).addClass('active-inline-edit');
	}
}

window.startAdminInlineEdit = function (row, url, previous_row = null) {
	// disable any inline-edit row if there exists one (can only edit one row at a time)
	$(".cancel-button:visible").click();

	// get the row object and enable the inline-edit (html) using js partial loading in the controller
	toggleAdminInlineEdit(row, true);
	$.ajax({
		type: "GET",
		url: url,
		data: {},
		success: function () {
			$(row).find(".submit-button-group").show();
			if ($(row).find(".submit-button-group").length > 0) {
				$(row).find(".edit-button-group").hide();
			}
		}
	});
}

window.stopAdminInlineEdit = function (row, previous_row) {
	// for each column, get the previous value and swap from input fields to text fields
	$(row).find('td').not('.controls').each(function () {
		let field = $(this).find('input, select').attr('id').toLowerCase();
		let value = $(previous_row).children().eq($(this).index()).text(); // get the previous value for this field

		// special cases for boolean columns (Active and Enabled) - this is ugly
		if (field === 'is_active') {
			value = value.trim() === "Active";
			$(this).html('<i class="far fa-' + (value ? 'info' : 'times') + '-circle"></i> ' + (value ? 'Active' : 'Inactive'));
			$(this).removeClass().addClass(value ? 'active-status' : 'inactive-status');
		} else if (field === 'is_enabled') {
			value = value.trim() === "Enabled";
			$(this).html('<i class="far fa-' + (value ? 'info' : 'times') + '-circle"></i> ' + (value ? 'Enabled' : 'Disabled'));
			$(this).removeClass().addClass(value ? 'enabled-status' : 'disabled-status');
		} else {
			$(this).html(value);
		}
	});

	toggleAdminInlineEdit(row);
}

window.submitAdminInlineEdit = function (row, url) {
	var d = {};
	d['authenticity_token'] = $('meta[name="csrf-token"]')[0].content;

	// get the edited object's fields and values to send to the controller
	d['admin_object'] = {}
	$(row).find('td').not('.controls').each(function () {
		let field = $(this).find('input, select').attr('id');
		d['admin_object'][field] = $(this).find('input, select').val();
	})
	clearToastNotifications();
	$.ajax({
		type: "POST",
		url: url,
		data: d,
		success: function () {
		},
		error: function(data){
			if (data.status === 403 || data.status === 401){
				openForbiddenErrorToast();
			} else {
				openErrorToast(data.responseText);
			}
			// var theInstructions = data.responseText;
			// var F=new Function (theInstructions);
			// return(F());
		},
		complete: function() {
		}
	});
}

window.clearToastNotifications = function () {
	$.toast().reset('all');
}

// Event hook for toggle status on row of table.
window.toggleEnabledStatus = function(self, action) {
	if ($("#admin_filter h1").text() !== "Users") {
		// The heading is not Users. If it is Users, the toggle will not run. Only way a User
		// can be deactived is via the form.

		// Get the row
		let row = $(self.parents("tr")[0]);
		let url = "";

		// Figure out url to toggle status
		if (row.find('.edit-button-group input[type=hidden]').length === 1) {
			// This row has a hidden field that can calculate the record id.
			url = row.find('.edit-button-group input[type=hidden]').val().replace(/edit$/, action);
		} else {
			// This row has anchors instead of hidden field to calculate the record id.

			// Go through each anchor until you find edit url and then you can work out which
			// url to use to toggle status.
			row.find('.edit-button-group a').each(function() {
				if ($(this).attr("href").match(/\/edit$/)) {
					// This anchor is the edit anchor.

					// Figure out the toggle url now that we have edit url.
					url = $(this).attr('href').replace(/edit$/, action);
					return false;// Break out of each loop.
				}
			});
		}
		let d = {};
		d['authenticity_token'] = window._token;
		// Toggle status of record.
		$.ajax({
			type: "GET",
			url: url,
			data: d,
			success: function (data) {
				if ($("#"+action+"_label_id").length > 0) {
					let cont = $("#"+$("#"+action+"_label_id").attr('data-id'));
					// If button has data-id, it might have an open form with control that needs to be updated
					if (data.match(/disable|inactive/i)) {
						cont.val("false");
					} else {
						cont.val("true");
					}
				}
				self.removeClass("enabled-status")
						.removeClass("disabled-status")
						.removeClass("active-status")
						.removeClass("inactive-status");
				// Update the status cell in the table.
				self.html(data);
			}
		});
	}
}

window.validUserPasswords = function (password, confirmation) {
	let invalid_messages = []

	if (password === '' && confirmation === '') {
		return invalid_messages;
	}
	if (password === '' || password === undefined || password === null) {
		invalid_messages.push('Password can\t be blank.');
	} else if (confirmation === '' || confirmation === undefined || confirmation === null) {
		invalid_messages.push('Password Confirmation can\t be blank.');
	} else if (password !== confirmation) {
		invalid_messages.push('Password and Password Confirmation doesn\'t match.');
	}
	if (password && password.length < 12) {
		invalid_messages.push('Password must be at least 12 characters.');
	}
	if (password && !password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g)) {
		invalid_messages.push('Password must include at least one lowercase letter, one uppercase letter and one digit');
	}

	if (invalid_messages.length > 0) {
		clearToastNotifications();
		openErrorToast(invalid_messages.join('<br><br>'));
		return false;
	} else {
		return true;
	}
}

const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

$(document).ready(function() {
	$(document).delegate("#user_profile", "click", function (e){
		e.preventDefault();
		$.ajax({
			type: "GET",
			url: $(this).attr('data-url'),
			data: {'selected_page': $(this).attr('data-selected-page')}, // to retain the selected tab (Home/Admin)
			success: function(data) { // the returned html form (data) gets used to set the html of the target element
				$('#user_profile_modal_form').html(data);
			}
		});
		return false;
	});

	$(document).delegate("button.clear-toast", "click", function(e){
		e.preventDefault();
		clearToastNotifications();
		return false
	});

	$(document).delegate("button#submitUserProfile", "click", function(e){
		e.preventDefault();
		let validPasswords = validUserPasswords($('input#user_password').val(), $('input#user_password_confirmation').val())
		if (!validPasswords) {
			return false;
		}

		let form = $('#profile_modal_form');
		$.ajax({
			type: "POST",
			url: form.attr('action'),
			data: form.serialize(),
			success: function() {
				if ($('#userProfile').length > 0) {
					$('#userProfile').modal('hide'); // not necessary, redirecting from the controller automatically hides modal
				} else {
					let c = setInterval(function() {
						openNoticeToast("Profile Updated");
						clearInterval(c);
					}, 1000);
				}
			},
			error: function(data) {
				if (data.status === 403 || data.status === 401){
					openForbiddenErrorToast();
				} else if (data.responseText) {
					let error_message = data.responseText;
					error_message = error_message.substring(error_message.indexOf("\"") + 1, error_message.lastIndexOf("\"")); // get rid of start and trailing " from to_json
					clearToastNotifications();
					openErrorToast(error_message);
				}
			}
		});
		return false;
	});

	$(document).delegate("#user_notification", "click", function(e){
		e.preventDefault();
		$("#previous_user_notifications").show();
		$.ajax({
			type: "GET",
			url: $(this).attr('data-url'),
			data: {'selected_page': $(this).attr('data-selected-page')}, // to retain the selected tab (Home/Admin)
			success: function(content) { // the returned html form (data) gets used to set the html of the target element
				$('#user_notification_modal_form').html(content);
			}
		});
		return false;
	});

	$(document).delegate("#previous_user_notifications", "click", function(e){
		e.preventDefault();
		let current_ids = [];
		$("#user_notification_modal_form .toast").each(function() {
			current_ids.push($(this).attr('data-id'));
		});
		$.ajax({
			type: "GET",
			url: $(this).attr('data-url'),
			data: {
				'current_ids': current_ids,
				'previous_notification': true,
				'selected_page': $(this).attr('data-selected-page')
			}, // to retain the selected tab (Home/Admin)
			success: function(content) { // the returned html form (data) gets used to set the html of the target element
				console.log(content);
				let html = document.createElement('html');
				html.innerHTML = content;
				if ($(html).find('.toast').length < 2) {
					$("#previous_user_notifications").hide();
				}
				let first_toast = $(html).find('.toast:first');
				if (first_toast.length > 0) {
					$("#no_new_notifications").remove();
					$('#user_notification_modal_form').append(first_toast);
				}
			}
		});
		return false;
	});

	$(document).delegate(".close-notification, .delete-notification", "click", function(e) {
		e.preventDefault();
		let notification = $(this);
		$.ajax({
			url: notification.attr("href"),
			type: 'DELETE',
			success: function(data) {
				$(notification.parents(".toast.show")[0]).remove();
				$("#no_new_notifications").remove();
				$("#previous_user_notifications").show();
				if ($("#user_notification_modal_form .toast.show").length == 0) {
					$("#user_notification .fa-bell-on").css("display","none");
					$("#user_notification .fa-bell").css("display","inline");
					$('#userNotification').modal('hide');
				}
			},
			error: function(data) {
				if (data.status === 403 || data.status === 401) {
					openForbiddenErrorToast();
				} else {
					openErrorToast("Unable to Close Notification");
				}
			}
		});

		return false;
	});

	$(document).delegate("#filter-list > .filter_options > .option_header > input.filter_checkbox", "click", function(){
		let top = $(this).parents('.filter_options')[0];
		let widget = $(top).find('.filter-list:visible');
		if ($(this).prop('checked')) {
			if (widget.length === 0) {
				// Open Widget
				$(top).find('.item-toggle').trigger('click');
			}
		} else {
			if (widget.length > 0) {
				// Close Widget
				$(top).find('.item-toggle').trigger('click');
			}
		}
	});

	$(document).delegate("#admin_filter #start-date,#admin_filter #end-date", "change", function(e){
		e.preventDefault();
		if (!$("#date_selected").prop("checked")) {
			$("#date_selected").click();
		}
		return false;
	});

	// Triggers after a key is clicked on generic search box field
	$(document).delegate("#custom-search #generic_search", "keyup", function(e){
		if ($(this).val() !== "") {
			// There is a value in the generic text search box field, so show clear button
			$(this).parent().find("button.reset").show();
		} else {
			// There is no value in the generic text search box field, so hide clear button
			$(this).parent().find("button.reset").hide();
		}
	});

	// Trigger that clears the search box
	$(document).delegate("#custom-search button.reset", "click", function(e){
		e.preventDefault();
		// Clear search box
		$("#custom-search #generic_search").val("");
		$($).save_filter_data();// Save the fact that search box has been cleared.
		$(this).hide(); // Hide clear button.
		return false;
	});

	$(document).delegate("#feedback_error", "click", function (e){
		e.preventDefault();
		$(".jq-toast-wrap.bottom-left .jq-toast-single").show();
		$(this).hide();
		return false;
	});

	$(document).delegate("#manual_import_details form", "submit", function(e){
		e.preventDefault();
		return false;
	});

	$(document).delegate("#upload_submission_btn", "click", function (e) {
		e.preventDefault();
		$("#feedback_error").hide();
		clearToastNotifications();
		$("#upload_submission_btn").attr("disabled", "disabled");
		var formData = new FormData();
		formData.append('excel_file', $('#excel_file')[0].files[0]);
		$("#submission_form_upload").append("<img alt='loading' class='ajax-load' src='loading.gif'/>");
		$.ajax({
			url : $("#manual_import_details form").attr('action'),
			type : 'POST',
			data : formData,
			processData: false,  // tell jQuery not to process the data
			contentType: false,  // tell jQuery not to set contentType
			success: function(data) {
				var json = $.parseJSON($.parseJSON(data));
				openNoticeToast(json.message);
				complete_submission_upload();
			},
			error: function(data) {
				if (data.status === 403 || data.status === 401) {
					openForbiddenErrorToast();
				} else {
					var msg = data.responseText;
					if (data.responseText !== "Success") {
						var json = $.parseJSON($.parseJSON(data.responseText));
						msg = json.message;
					}
					openErrorToast(msg);
					complete_submission_upload();
				}
			}
		});
		return false;
	});

	function complete_submission_upload() {
		let c = setTimeout(function () {
			$("#upload_submission_btn").removeAttr("disabled");
			clearInterval(c);
		}, 500);
		$("#submission_form_upload img.ajax-load").remove();
	}

	$(document).on('click', '.gen-token-button', function(e){
		e.preventDefault();
		if (confirm("Generate New API Token? \n\n This will invalidate all current API configurations.")) {
			$.ajax( {
				type: "POST",
				url: $(this).attr('data-url'),
				data: {}
			})
		} else {
			// Do nothing
		}
		return false;
	});

	$(document).on('click', '.send-test-email-button', function(e){
		e.preventDefault();

		let send_test_url = $(this).attr('data-url');
		let email = "who is specified in the Email Notifications.";
		$("td button.send-test-email-button").each(function() {
			if ($(this).attr('data-url') === send_test_url) {
				email = $(this).parent().prev().text();
				return false;// Break out of loop.
			}
		});

		if (confirm("Generate Test Email? \n\n This will send out a test email to "+email)) {
			$.ajax( {
				type: "POST",
				url: send_test_url,
				data: {}
			})
		} else {
			// Do nothing
		}
		return false;
	});

	// expand all sub menu functionality
	$(document).on('click', '.menu-expand-all', function(e) {
		e.preventDefault();
		// Convert minimize to expand icon and vice versa.
		$(this).find("> .minimize-icon").toggle();
		$(this).find("> .expand-icon").toggle();
		if ($(this).find("> .expand-icon:visible").length > 0) {
			// Currently expanded all sections, so change label to Collapse All
			$(this).find("p").text("Collapse All");
		} else {
			// Current collapsed all sections, so change back to Expand All
			$(this).find("p").text("Expand All");
		}
		// Get current menu section
		let menu_filters = $($(this).parents(".menu_filters")[0]);
		// Hide/Show sub menus
		menu_filters.find("nav.sub-menu li.tab-links").toggle();
		// Add cursor focus to menu search box, so after open sub menu, you can type
		// and filter menu list
		menu_filters.find("label.page-menu-label").trigger("click");
	});

	// expand all functionality
	$(document).on('click', '#expand_all', function(e) {
		e.preventDefault();
		// Convert minimize to expand icon and vice versa.
		$(this).find("> .minimize-icon").toggle();
		$(this).find("> .expand-icon").toggle();

		if ($(this).find("> .expand-icon:visible").length > 0) {
			// Currently expanded all sections, so change label to Collapse All
			$(this).find("p").text("Collapse All");
		} else {
			// Current collapsed all sections, so change back to Expand All
			$(this).find("p").text("Expand All");
		}

		let mi = $(this).find("> .minimize-icon:visible").length === 0;
		let ei = $(this).find("> .expand-icon:visible").length === 0;

		$(".filter_options").each(function() {
			if ($(this).find(".minimize-icon:visible").length > 0) {
				if (mi) {
					$(this).find(".minimize-icon").click();
				}
			} else if ($(this).find(".expand-icon:visible").length > 0) {
				if (ei) {
					$(this).find(".expand-icon").click();
				}
			}
		});
		return false;
	});

	$(document).on('click', '.item-toggle', function(e) {
		e.preventDefault();
		$(this).parent().find(".filter-list").toggle();
		$(this).parent().find(".expand-icon").toggle();
		$(this).parent().find(".minimize-icon").toggle();
		// As soon as you open filter with search box, add cursor focus to search
		// box so you can type what you want to filter options by.
		$(this).parent().find(".option_header.filter-list > label").trigger("click");
		return false;
	});

	$(document).on('click', '.close-inline-form', function(e) {
		e.preventDefault();
		clearToastNotifications();
		$($(this).parents("tr.inline-form")[0]).remove();
		return false;
	});

	$(document).on('change', '.filter_item input[type=checkbox]', function() {
		let p = $(this).parents(".filter_options");
		if (p !== undefined) {
			if ($(this).is(':checked') && !$(p[0]).find(".option_header input[type=checkbox]").is(':checked')) { // if child filter item is checked (true) and parent filter checkbox isn't checked (false)
				$(p[0]).find(".option_header input[type=checkbox]").prop('checked', true); // set parent to checked (true)
			}
		}
	});

	$(document).on('keyup', '.filter_options input.filter-list', function() {
		let p = $(this).parents(".filter_options");
		if (p !== undefined) {
			if (!$(p[0]).find(".option_header input[type=checkbox]").is(':checked')) { // if child filter item is checked (true) and parent filter checkbox isn't checked (false)
				$(p[0]).find(".option_header input[type=checkbox]").prop('checked', true); // set parent to checked (true)
			}
		}
	});

	$(document).on('click', '#clear_all_button', function(e) {
		localStorage.clear();// Clear local so filter details are not saved, so if you went to another page,
		// the previous filter will not be used until you edit the filter and click submit.
		// Clear search form
		$("#generic_search, .date-field input, .filter_options input[type=text]").val('');
		$("input.filter_checkbox").prop('checked', false);
		$("#generic_search").keyup();
	});

	$(document).on('click', '#external_import_button', function(e) {
		$.ajax({
			type: "POST",
			url: 'imports/external_import',
			data: '',
			success: function(data) {
				let message = data;
				clearToastNotifications();
				$("#apply_filter_button").click();
				let c = setTimeout(function () {
					openNoticeToast(message);
					clearInterval(c);
				},1000);
			},
			error: function(data) {
				if (data.status === 403 || data.status === 401) {
					openForbiddenErrorToast();
				} else if (data.responseText) {
					let error_message = data.responseText;
					// error_message = error_message.substring(error_message.indexOf("\"") + 1, error_message.lastIndexOf("\"")); // get rid of start and trailing " from to_json
					clearToastNotifications();
					openErrorToast(error_message);
				}
			}
		});
	});

	$(document).on('click', '#manual_import_button', function(e) {
		e.preventDefault();
		$("#manual_import_details").show();
		$("#form_details").hide();
		return false;
	});

	function submitModal(form, action, modal_name){
		$.ajax({
			type: "POST",
			url: action,
			data: form.find('input, textarea, select').serialize(),
			success: function(data) {
				var at = $('#admin-table');
				var current_page_no = at.DataTable().page();
				at.DataTable().page( current_page_no ).draw( 'page' );
				$('#'+modal_name).modal('hide'); // not necessary, redirecting from the controller automatically hides modal;
				console.log(data);
				modal_success_controller(modal_name);
			},
			error: function(data) {
				if (data.status === 403 || data.status === 401){
					openForbiddenErrorToast();
				} else if (data.responseText) {
					let error_message = data.responseText;
					error_message = error_message.substring(error_message.indexOf("\"") + 1, error_message.lastIndexOf("\"")); // get rid of start and trailing " from to_json
					clearToastNotifications();
					openErrorToast(error_message.replaceAll("\"",""));
				}
			}
		});
	}

	function modal_success_controller(modal_name) {
		let tmp_name = modal_name; /* Add modal_name to temp variable so we can work out which tab user is currently using */
		tmp_name = tmp_name.replace(/^reporting_/, '').replace(/_modal$/, '');
		/* Removing reporting and modal words and then use what is left, split _ into array of words
		and upper case first letter of each of these words to make a title*/
		let parts = tmp_name.split('_');
		let title = [];
		for(let i=0;i<parts.length;i++) {
			title.push(parts[i].charAt(0).toUpperCase() + parts[i].slice(1));
		}
		modal_success_msg(title.join(' '), modal_name);
	}

	function modal_success_msg(model, modal_name) {
		var msg = model+" was created successfully";
		if ($("#"+modal_name+"_form").attr("class").match("edit_")) {
			msg = model+" was updated successfully";
		}
		openNoticeToast(msg);
	}

	$(document).on('click', 'button#submitUserForm', function(e) {
		let invalid = true;
		let validPasswords = validUserPasswords($('input#user_password').val(), $('input#user_password_confirmation').val())
		if (!validPasswords) {
			invalid = false;
		}

		if (!$("#user_company_id").val()) {
			openErrorToast("Must select a company");
			invalid = false;
		}

		if (!invalid){
			e.preventDefault();
			return false;
		}
		let form = $('#user_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'user_modal');
	});

	$(document).on('click', 'button#submitCompanyForm', function(e) {
		let invalid = true;
		if (!$("#company_name").val()) {
		  openErrorToast("Must have a Company Name");
			invalid = false;
		}
		if (!$("#company_company_type_id").val()) {
			openErrorToast("Must have a Company Type");
			invalid = false;
		}
		// Remove all spaces from email string
		$("#company_notification_email").val($("#company_notification_email").val().replace(/ /g,''));
		// If email has commas, assume multiple email addresses
		let email_addresses = $("#company_notification_email").val().split(',')
		email_addresses.forEach( function(email) {
			// As you go through all emails, validate each one separately.
			if (email && !validateEmail(email)){
				openErrorToast("Notification Email must be a valid email address");
				invalid = false;
			}
		});
		if (!invalid) {
			return false;
		}

		let form = $('#company_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'company_modal');
	});

	$(document).on('click', 'button#submitActivityForm', function(e) {
		let invalid = true;
		if (!$("#activity_name").val()) {
			openErrorToast("Must have an Activity Name");
			invalid = false;
		}
		if (!$("#activity_code").val()) {
			openErrorToast("Must have an Activity Code");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#activity_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'activity_modal');
	});

	$(document).on('click', 'button#submitConsumableForm', function(e) {
		let invalid = true;
		if (!$("#consumable_name").val()) {
			openErrorToast("Must have an Consumable Name");
			invalid = false;
		}
		if (!$("#consumable_code").val()) {
			openErrorToast("Must have an Consumable Code");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#consumable_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'consumable_modal');
	});

	$(document).on('click', 'button#submitDrillMethodForm', function(e) {
		let invalid = true;
		if (!$("#drill_method_name").val()) {
			openErrorToast("Must have a Drill Method Name");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#drill_method_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'drill_method_modal');
	});

	$(document).on('click', 'button#submitEquipmentForm', function(e) {
		let invalid = true;
		if (!$("#equipment_name").val()) {
			openErrorToast("Must have an Equipment Name");
			invalid = false;
		}
		if (!$("#equipment_code").val()) {
			openErrorToast("Must have an Equipment Code");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#equipment_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'equipment_modal');
	});

	$(document).on('click', 'button#submitConnectorConfigurationForm', function(e) {
		let invalid = true;
		if ($("#connector_configuration_company_id").val() == "") {
			openErrorToast("Must have a Company");
			invalid = false;
		}
		if ($("#connector_configuration_connector_id").val() == "") {
			openErrorToast("Must have a Connector");
			invalid = false;
		}
		if ($("#connector_configuration_form_type_id").val() == "") {
			openErrorToast("Must have a Form Type");
			invalid = false;
		}
		if ($("#connector_configuration_connector_group_mapping").val() == "") {
			openErrorToast("Must have a Group");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#connector_configuration_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'connector_configuration_modal');
	});

	$(document).on('click', 'button#submitLocationForm', function(e) {
		let invalid = true;
		if (!$("#location_name").val()) {
			openErrorToast("Must have an Location Name");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#location_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'location_modal');
	});

	$(document).on('click', 'button#submitHoleForm', function(e) {
		let invalid = true;
		if (!$("#hole_planned_id").val()) {
			openErrorToast("Must have a Planned ID");
			invalid = false;
		}
		if (!$("#hole_hole_id").val()) {
			openErrorToast("Must have an ID");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#hole_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'hole_modal');
	});

	$(document).on('click', 'button#submitContractDetailForm', function(e) {
		let invalid = true;
		if (!$("#reporting_contract_detail_Description").val()) {
			openErrorToast("Must have a Name");
			invalid = false;
		}
		if (!$("#reporting_contract_detail_Contract_Item_Type_ID").val()) {
			openErrorToast("Must have a Item Category");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#contract_detail_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'reporting_contract_detail_modal');
	});

	$(document).on('click', 'button#submitContractTUMGroupForm', function(e) {
		let invalid = true;
		if (!$("#reporting_contract_tum_group_Name").val()) {
			openErrorToast("Must have a Name");
			invalid = false;
		}
		if (!$("#reporting_contract_tum_group_Description").val()) {
			openErrorToast("Must have a Description");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#contract_tum_group_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'reporting_contract_tum_group_modal');
	});

	$(document).on('click', 'button#submitContractTumItemForm', function(e) {
		let invalid = true;
		if (!$("#reporting_contract_tum_item_TUM_Type_ID").val()) {
			openErrorToast("Must have a TUM Type");
			invalid = false;
		}
		if ($("input.Activities:checked").length == 0) {
			openErrorToast("Must have at least one Activity");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#contract_tum_item_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'reporting_contract_tum_item_modal');
	});

	$(document).on('click', 'button#submitTenementForm', function(e) {
		let invalid = true;
		if (!$("#tenement_name").val()) {
			openErrorToast("Must have a Tenement Name");
			invalid = false;
		}
		if (!invalid) {
			return false;
		}

		let form = $('#tenement_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'tenement_modal');
	});

	$(document).on('click', 'button#submitNotificationForm', function(e) {
		let valid = true;
		if (!$("#notification_message").val()) {
			openErrorToast("Must have a Notification Message");
			valid = false;
		}
		if (!valid) {
			return false;
		}

		let form = $('#notification_modal_form');
		let action = $($(this).parents("form")[0]).attr("action");
		submitModal(form, action, 'notification_modal');
	});

	$(document).on('click', '.select-all', function() {
		let checked = $(this).prop('checked');
		$(this).parent().parent().find("ul li.option_header input").prop('checked', checked);
		let fr = $(this).parent().parent().find('.filter-relationship');
		fr.val('').trigger('keyup');
	});

	$(document).on('click', '.close-jq-toast-single', function (e) {
		e.preventDefault();
		$(".jq-toast-single").remove();
		return false;
	});

	$(document).on('keyup', '.filter-relationship', function () {
		let search_text = $(this).val().toLowerCase();
		let lis = $(this).parent().parent().find("ul li.option_header");
		lis.each(function() {
			if ($(this).find("input:checkbox").length > 0 && $(this).find("input:checkbox").prop('checked')) {
				// If a checkbox in the filter list is checked, keep it visible
				$(this).show();
			} else if ($(this).find('label').text().toLowerCase().match(search_text)) {
				// If text written in filter search, only display filter options that match
				$(this).show();
			} else {
				// If text written in filter search, hide filter options that don't match
				$(this).hide();
			}
		});
	});

	$(document).on('click', '.filter-list > li > input:checkbox', function () {
		let list = $(this).parent().parent();
		$(jQuery).rearrange_checkbox_list(list);
		if ($(this).prop('checked')) {
			if ($(this).parents(".filter_options").length > 0) {
				// If child filter checkbox is checked, check the main parent filter checkbox
				$($(this).parents(".filter_options")[0]).find("> .option_header input:checkbox").prop("checked", true);
			}
		}
	});

	$(document).on('click', '.enabled-status', function() {
		if ($(this).find("select").length === 0) {
			// This cell does not have a select field, so assume we can toggle its status.
			toggleEnabledStatus($(this), 'change_enabled');
		}
	});
	$(document).on('click', '.disabled-status', function() {
		if ($(this).find("select").length === 0) {
			// This cell does not have a select field, so assume we can toggle its status.
			toggleEnabledStatus($(this), 'change_enabled');
		}
	});
	$(document).on('click', '.active-status', function() {
		if ($(this).find("select").length === 0) {
			// This cell does not have a select field, so assume we can toggle its status.
			toggleEnabledStatus($(this), 'change_active');
		}
	});
	$(document).on('click', '.inactive-status', function() {
		if ($(this).find("select").length === 0) {
			// This cell does not have a select field, so assume we can toggle its status.
			toggleEnabledStatus($(this), 'change_active');
		}
	});

	$(document).on('click', '.save-button', function() {
		console.log("Save Me");
		let row = $(this).closest('td').parent();
		let url = $(this).siblings('input#submit_path').val();
		submitAdminInlineEdit(row, url);
		// savePrevData(row);
	});

	let previous_row = null; // store the previous values for the current inline-edit row to use when disabling inline-edit

	$(document).on('click', '.cancel-button', function(e) {
		e.preventDefault();
		$("#feedback_error").hide();
		clearToastNotifications();
		let row = $(this).closest('td').parent();
		row.find('input[type=text]').each(function() {
			$(this).parent().text($(this).attr('oldval'));
		});
		row.find('select').each(function() {
			let value = $(this).attr('oldval') === 'true';
			if ($(this).attr('id').toLowerCase().match(/is_active/)) {
				$(this).parent().html('<i class="far fa-' + (value ? 'info' : 'times') + '-circle"></i> ' + (value ? 'Active' : 'Inactive'));
				$(this).parent().removeClass().addClass(value ? 'active-status' : 'inactive-status');
			} else if ($(this).attr('id').toLowerCase().match(/is_enable/)) {
				$(this).parent().html('<i class="far fa-' + (value ? 'info' : 'times') + '-circle"></i> ' + (value ? 'Enabled' : 'Disabled'));
				$(this).parent().removeClass().addClass(value ? 'active-status' : 'inactive-status');
			} else {
				if ($(this).attr('oldval')) {
					$(this).parent().text($(this).attr('oldval').replace(/^None$/,''));
				} else {
					$(this).parent().text("");
				}
			}
		});
		toggleAdminInlineEdit(row);
		$(row).find(".submit-button-group").hide();
		if ($(row).find(".edit-button-group").length > 0) {
			$(row).find(".edit-button-group").show();
		}
		previous_row = null;
		return false;
	});

	$(document).on('click', '#cancel-new-button', function(e) {
		e.preventDefault();
		let row = $(this).closest('td').parent();
		$(row).remove();
		return false;
	});

	$(document).on('click', '.edit-button', function(e) {
		e.preventDefault();
		let url = $(this).siblings('input#edit_path').val();

		if ($(this).hasAttr('data-bs-toggle') && $(this).attr('data-bs-target')) {
			// Open Modal window
			let modal_target = $(this).attr('data-bs-target');
			let d = {};
			d['authenticity_token'] = window._token;
			$.ajax({
				type: "GET",
				url: url,
				data: d,
				success: function (data) { // the returned html form (data) gets used to set the html of the target element
					$(modal_target + '_form').html(data);
					$('.trigger-change').trigger('change');
					$('select.trigger-change').each(function () {
						$("select#" + $(this).attr('id')).focus().change();
						$("input[checked=checked]").prop('checked', true);
					});
				}
			});
		} else if($(this).hasClass('inline-form')) {
			// Open inline form
			$($(this).parents("table")[0]).find("tr.inline-form").remove();
			let tr = $($(this).parents("tr")[0]);
			let d = {};
			d['authenticity_token'] = window._token;
			$.ajax({
				type: "GET",
				url: url,
				data: d,
				success: function (data) { // the returned html form (data) gets used to set the html of the target element
					let html = document.createElement('html');
					html.innerHTML = data;
					let body = $(html).find('body:first');
					let content = $(body[0]).html().replace("<body>", "").replace("</body>",""); // load the corresponding admin screen (depending on the url) into the div container
					$($(this).parents("table")[0]).find("tr.inline-form").remove();// Remove all previous edit and new forms
					tr.after("<tr class='inline-form'><td colspan='"+tr.find("td").length+"'>"+content+"</td></tr>");
					$('.trigger-change').trigger('change');
					$('select.trigger-change').each(function () {
						$("select#" + $(this).attr('id')).focus().change();
						$("input[checked=checked]").prop('checked', true);
					});
				}
			});

		} else {
			// Open row line form
			let row = $(this).closest('td').parent();
			// disable any other inline-edit if exists (can only edit one row at a time), enable this inline-edit row (html) and store the previous row for its values
			startAdminInlineEdit(row, url, previous_row);
			previous_row = row.clone();
			savePrevData(row);
		}
		return false;
	});

	$(document).on('click', '.delete-button-group a.delete-button', function(e) {
		e.preventDefault();
		if (confirm("Are you sure you wish to Delete?")){
			var d = {};
			d['authenticity_token'] = $('meta[name="csrf-token"]')[0].content;
			clearToastNotifications();
			let row = $($(this).parents("tr")[0]);
			$.ajax({
				type: "DELETE",
				url: $(this).attr("href"),
				data: d,
				success: function () {
					row.remove();
				},
				error: function(){
					openErrorToast("Unable to Delete The Record");
				},
			});
		}
		return false;
	});

	$(document).on('click', '#add_new_button', function(e) {
		e.preventDefault();
		$(".cancel-button:visible").click();
		$("#new_item_row").remove();
		// insert new table row at the top of the table (url varies depending on the admin screen)
		let content = $("#new_row_code table tr").html();
		$('#admin-table tbody tr:first').before("<tr id='new_item_row'>"+content+"</tr>");

		// get the url to trigger the new item creation and get an empty object
		let row = $('#new_item_row');
		let url = $(row).children('input#new_path').val();
		startAdminInlineEdit(row, url);

		return false;
	});

	$(document).on('click', '.new-modal-record', function(e) {
		e.preventDefault();
		let modal_form_sel = $(this).attr('data-bs-target')+'_form';

		$.ajax({
			type: "GET",
			url: $(this).attr('data-url'),
			data: {},
			success: function(data) { // the returned html form (data) gets used to set the html of the target element
				$(modal_form_sel).html(data); // data has full DOM so it can render modal styles correctly.
				$('.trigger-change').trigger('change');
				$("#role_label").hide();
			}
		});
		return false;
	});

	$(document).on('click', '.new-inline-form-record', function(e) {
		e.preventDefault();
		let table = $("#admin-table:visible:first");
		let url = $(this).attr('data-url');
		let tb = $("#admin-table:first tbody:first");
		tb.find("tr.inline-form").remove(); // Remove all previous edit and new forms
		let d = {};
		d['authenticity_token'] = window._token;
		$.ajax({
			type: "GET",
			url: url,
			data: d,
			success: function (data) { // the returned html form (data) gets used to set the html of the target element
				let html = document.createElement('html');
				html.innerHTML = data;
				let body = $(html).find('body:first');
				let content = $(body[0]).html().replace("<body>", "").replace("</body>",""); // load the corresponding admin screen (depending on the url) into the div container
				tb.find("tr.inline-form").remove(); // Remove all previous edit and new forms
				tb.prepend("<tr class='inline-form'><td colspan='"+table.find("thead th").length+"'>"+content+"</td></tr>");
				$('.trigger-change').trigger('change');
				$('select.trigger-change').each(function () {
					$("select#" + $(this).attr('id')).focus().change();
					$("input[checked=checked]").prop('checked', true);
				});
			}
		});
		return false;
	});

	$(document).on('click', '.import-button', function(e) {
		e.preventDefault();
		let url = $(this).siblings('input#import_path').val();
		let d = {};
		d['authenticity_token'] = window._token;
		$.ajax({
			type: "GET",
			url: url,
			data: d,
			success: function(data) { // the returned html form (data) gets used to set the html of the target element

			}
		});
		return false;
	});

	$(document).on('change', '#admin-table input[type=text], #admin-table select, #admin-table checkbox, #admin-table radio', changeClickRow);
	$(document).on('click', '#admin-table input[type=text], #admin-table select, #admin-table checkbox, #admin-table radio', changeClickRow);

	function change_by_slug_event(select_id, slug_name, slug='by_company', by_id = 'company', override_first_choice_instructions = '') {
		$("select#"+select_id).empty();
		if ($("select#"+by_id).val() == "") {
			let instruction = "Please select "+by_id+" first";
			if (override_first_choice_instructions !== '') {
				instruction = override_first_choice_instructions;
			}
			$("select#"+select_id).append("<option value=''>"+instruction+"</option>");
		} else {
			$("select#"+select_id).hide();
			$("select#"+select_id).parent().append("<img alt='loading' class='ajax-load' src='/loading.gif'/>");
			$.ajax({
				type: "GET",
				url: '/'+slug_name+'/'+slug+'/'+$("select#"+by_id+' option:selected').text().replace('/','%2F'),
				success: function(content) {
					let values = $.parseJSON(content.data);
					$("select#"+select_id).append("<option value=''>Please select</option>");
					for(let i = 0; i<values.length; i++){
						let name = values[i];
						$("select#"+select_id).append("<option value='"+name+"'>"+name+"</option>");
					}
				},
				complete: function() {
					let c = setTimeout(function() {
						$("select#"+select_id).show().parent().find('img.ajax-load').remove();

						clearInterval(c);
					}, 500);
				}
			});
		}
	}

	$(document).on('change', 'select#connector_configuration_company_id', function(e) {
		e.preventDefault();
		change_by_slug_event(
			'connector_configuration_connector_group_mapping',
			'connector_group_mappings',
			'by_company',
			'connector_configuration_company_id'
			);
		return false;
	});

	$(document).on('change', '#admin-table select#company', function(e) {
		let ret=true;
		if ($("select#connector_group_mapping").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("connector_group_mapping", "connector_group_mappings");
		}
		if ($("select#activity").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("activity", "activities");
		}
		if ($("select#consumable").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("consumable", "consumables");
		}
		if ($("select#equipment").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("equipment", "equipment");
		}
		if ($("select#staff").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("staff", "staffs");
		}
		if ($("select#rig").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("rig", "rigs");
		}
		if ($("select#contract_header").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("contract_header", "reporting/contract_headers");
		}
		if ($("select#drill_method").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("drill_method", "drill_methods");
		}
		return ret;
	});

	$(document).on('change', '#admin-table select#tenement', function(e) {
		let ret=true;
		if ($("select#ore_body").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("ore_body", "ore_bodies", "by_tenement", "tenement");
		}
		return ret;
	});

	$(document).on('change', 'select#hole_company_id', function(e) {
		let ret=true;
		if ($("select#hole_rig_id").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("hole_rig_id", "rigs", "by_company", "hole_company_id");
		}
		return ret;
	});

	$(document).on('change', 'select#hole_tenement_id', function(e) {
		let ret=true;
		if ($("select#hole_ore_body_id").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event("hole_ore_body_id", "ore_bodies", "by_tenement", "hole_tenement_id");
		}
		return ret;
	});

	$(document).on('change', 'select#reporting_contract_header_Company_ID', function(e) {
		let ret=true;
		if ($("select#reporting_contract_header_Company_ID").length > 0) {
			e.preventDefault();
			ret=false;
			change_by_slug_event(
				"reporting_contract_header_Location_ID",
				"reporting/locations",
				'by_company',
				'reporting_contract_header_Company_ID',
				'Please select company first'
			);
		}
		return ret;
	});

	$(document).on('change', 'select#connector_configuration_connector_id', function (e) {
		e.preventDefault();
		$("#key_pairs .accordion h3, #key_pairs .accordion ul").remove();
		if ($(this).val() !== '') {
			$("#key_pairs").parent().append("<img alt='loading' class='ajax-load' src='loading.gif' />");
			$.ajax({
				type: "GET",
				url: "/connectors/ajax_keys/"+$(this).val(),
				success: function(data) {
					$("#key_pairs").append(data);
					$(".accordion").accordion({ active: 0 });
				},
				complete: function() {
					let c = setTimeout(function() {
						$('img.ajax-load').remove();
						clearInterval(c);
					}, 500);
				}
			});
		}
		return false;
	});

	let previous_contract_item_type;
	let previous_uom;
	$(document).on('focus', 'select#reporting_contract_detail_Contract_Item_Type_ID', function (e) {
		e.preventDefault();
		previous_contract_item_type = $('select#reporting_contract_detail_Contract_Item_Type_ID option:selected').text();
	});

	$(document).on('change', 'select#reporting_contract_detail_Contract_Item_Type_ID', function (e) {
		e.preventDefault();
		$('.form-group.row .schedule-item-category-dropdown').hide();
		let cit = $( "select#reporting_contract_detail_Contract_Item_Type_ID option:selected" ).text();
		$('.form-group.row .schedule-item-category-dropdown.'+cit.toLowerCase()).show();

		if ($('select#reporting_contract_detail_Contract_Item_Type_ID option:selected').text() !== previous_contract_item_type) {
			$('.form-group.row .schedule-item-category-dropdown.'+cit.toLowerCase()+' input[type=checkbox]').prop('checked', false);
		}

		if ($('.new_reporting_contract_detail:visible').length > 0) {
			let defaultUOMId = $('.form-group.row .schedule-item-category-dropdown.'+cit.toLowerCase()).attr('data-default-uom-id');
			$('#reporting_contract_detail_Unit_Of_Measure_ID').val(defaultUOMId);
		}

		previous_uom = $('select#reporting_contract_detail_Unit_Of_Measure_ID option:selected').text();
		$('#reporting_contract_detail_Unit_Of_Measure_ID').change();
		return false;
	});

	$(document).on('change', 'select#reporting_contract_detail_Unit_Of_Measure_ID', function (e) {
		e.preventDefault();
		$('.uom-details').hide();
		if ($('select#reporting_contract_detail_Unit_Of_Measure_ID option:selected').text() !== previous_uom) {
			$('.uom-details input').val('');
			$('.uom-details select').val('0');
		}
		if ($('select#reporting_contract_detail_Unit_Of_Measure_ID option:selected').text()) {
			$('.uom-details.'+$('select#reporting_contract_detail_Unit_Of_Measure_ID option:selected').text().toLowerCase()).show();
		}
		return false;
	});

	$(document).on('change', '#saved-filters input[type=radio]', function(e) {
		// Now that the default filter values have been set, run the search
		let c = setTimeout(function() {
			if ($('#apply_filter_button').length > 0) {
				$(jQuery).load_saved_filters();
				clearInterval(c);
			}
		},500);
	});

	$(document).on('click', '#save_filter', function(e) {
		e.preventDefault();

		let form = $('#saved_filter_form');

		let d = $('#saved_filter_form').serializeArray();
		$.each($('#submission_filters_form').serializeArray(), function() {
			if (this.name !== 'filter') {
				d.push(this);
			}
		});
		let filter_ids = [];
		$('#saved-filters div.saved-filter-item div.option_header input:checked').each( function() {
			filter_ids.push($(this).val());
		});

		filter_ids.forEach( function(filter_id) {
			d.push({name: 'selected_filters[]', value: filter_id });
		});

		$.ajax({
			type: form.attr('method'),
			url: form.attr('action'),
			data: d,
			error: function (xhr) {
				if (xhr.status === 403 || xhr.status === 401){
					openForbiddenErrorToast();
				} else if (xhr.responseText) {
					openErrorToast(xhr.responseText);
				}
			}
		});
	});

	$(document).on('click', '#save_filter_button', function() {
		if ($('#saved_filter_form').length === 0) {
			$.ajax({
				type: "GET",
				url: $("#save_filter_button_path").val(),
				data: {},
				success: function () {
					if ($('.saved-filter-item').length > 0) {
						$('#filters').animate({
							scrollTop: $('.saved-filter-item').last().offset().top
						}, 'slow');
					}
				}
			});
		}
	});

	$(document).on('click', '#set_default_button', function() {
		if (confirm("Save current saved filter(s) as the default?")){
			let filter_ids = [];
			$('#saved-filters div.saved-filter-item div.option_header input:checked').each( function() {
				filter_ids.push($(this).val());
			});
			$.ajax({
				type: "POST",
				url: $("#save_set_default_filters_path").val(),
				data: {'selected_filters': filter_ids}
			});
		}
	});

	// disable saved filter form's default submit and use custom submit
	$(document).on('keydown', '#saved_filter_form #filter_name', function(e) {
		let code = e.keyCode || e.which;
		if (code === 13) { // Enter keycode
			e.preventDefault();
			$("#save_filter").click();
			return false;
		}
	});

	$(document).on('keydown', function(event) {
		if (event.key === "Escape") {
			// On this page, user has clicked the Esc button on their keyboard.

			// On certain pages, the cancel button to called slightly differently, so this is a nice
			// way to test to work out which cancel button is on the page currently and clicking that
			// specific cancel button to cancel the form changes.
			if ($("#cancel_button:visible").length > 0) {
				$("#cancel_button").click();
			} else if ($("#admin-right #cancel-new-button:visible").length > 0) {
				$("#admin-right #cancel-new-button").click();
			} else if ($("button.btn.close-inline-form:visible").length > 0) {
				$("button.btn.close-inline-form").click();
			} else if ($(".cancel-button:visible").length > 0) {
				$(".cancel-button").click();
			} else if ($("#close_contract:visible").length > 0) {
				window.location = $("#close_contract").attr("href");
			}
		}
	});

	$(document).on('click', 'button#cancel_filter', function(e) {
		e.preventDefault();
		$("#saved_filter_form").parent().remove();
		return false;
	})

	$(document).on('click', '#submit_csv_upload', function() {
		let form = $('#csv_upload_form');
		$.ajax({
			type: form.attr('method'),
			url: form.attr('action'),
			data: new FormData(form[0]),
			processData: false,
			contentType: false,
			success: function() {
				$('#csvUpload').modal('hide');
				$("nav.menu .tablinks.active").click();
			},
			error: function(xhr) {
				if (xhr.status === 403 || xhr.status === 401){
					openForbiddenErrorToast();
				} else if (xhr.responseText) {
					clearToastNotifications();
					openErrorToast(xhr.responseText);
				}
			}
		})
	});

	$(document).on("click", "#contract_header_form button.btn-primary", function(e) {
		e.preventDefault();
		$.ajax({
			url: $("#contract_header_form").attr('action'),
			data: $("#contract_header_form").serialize(),
			type: 'POST',
			success: function (response) {
				console.log("Success");
				clearToastNotifications();
			},
			error: function(response) {
				if (response.status === 403 || response.status === 401){
					openForbiddenErrorToast();
				} else {
					let j = $.parseJSON(response.responseText);
					let errors = j.errors.split(',');
					clearToastNotifications();
					for(let i=0;i<errors.length;i++){
						let e = errors[i].replace('Description', 'Name').replace('Contract', 'Schedule');
						openErrorToast(e);
					}
				}
			}
		});
		return false;
	});

	// This is temporary and is only running for short time for BHP during initial development.
	$(document).on("click", "#update_finance_tables", function(e) {
		e.preventDefault();
		$(this).find('span').text('Updating');
		$(this).append("<img alt='loading' class='ajax-load' src='/loading.gif'/>");
		let self = $(this);
		$.ajax({
			type: "PATCH",
			url: $(this).attr('href'),
			success: function () {
				alert('Finance Tables Update Queued');
			},
			error: function() {
				alert('There was an issue with Updating the Finance Tables');
			},
			complete: function () {
				self.find('img').remove();
				self.find('span').text('Update');
			}
		});
		return false;
	});

	$(document).on("click", "#contract_tum_group_form button.btn-primary", function(e) {
		e.preventDefault();
		$.ajax({
			url: $("#contract_tum_group_form").attr('action'),
			data: $("#contract_tum_group_form").serialize(),
			type: 'POST',
			success: function (response) {
				console.log("Success");
				clearToastNotifications();
			},
			error: function(response) {
				if (response.status === 403 || response.status === 401){
					openForbiddenErrorToast();
				} else {
					let j = $.parseJSON(response.responseText);
					let errors = j.errors.split(',');
					clearToastNotifications();
					for(let i=0;i<errors.length;i++){
						openErrorToast(errors[i]);
					}
				}
			}
		});
		return false;
	});

	$(document).on('click', '#search_contract_details',function(e) {
		e.preventDefault();
		$.ajax({
			type: "GET",
			data: {
				generic_search: $("#generic_search").val(),
			},
			url: "/reporting/contract_headers/"+$(this).parent().attr("data-id")+"/search_contract_details",
			success: function (data) {
				let html = document.createElement('html');
				html.innerHTML = data;
				let body = $(html).find('body:first');
				let content = $(body[0]).html().replace("<body>", "").replace("</body>",""); // load the corresponding admin screen (depending on the url) into the div container
				$("#contract_detail_results").html(content);
			},
		});
		return false;
	});

	$(document).on('click', '#search_contract_tum_items',function(e) {
		e.preventDefault();
		$.ajax({
			type: "GET",
			data: {
				generic_search: $("#generic_search").val(),
			},
			url: "/reporting/contract_tum_groups/"+$(this).parent().attr("data-id")+"/search_contract_tum_items",
			success: function (data) {
				let html = document.createElement('html');
				html.innerHTML = data;
				let body = $(html).find('body:first');
				let content = $(body[0]).html().replace("<body>", "").replace("</body>",""); // load the corresponding admin screen (depending on the url) into the div container
				$("#contract_tum_item_results").html(content);
			},
		});
		return false;
	});

	function changeClickRow() {
		$("#previousURL").val('');
		let row = $(this).closest('td').parent();
		previous_row = row.clone();
	}

	function savePrevData(row) {
		var c = setInterval(function() {
			if (row.find('input[type=text],select').length > 0) {
				row.find('input[type=text],select').each(function() {
					$(this).attr('oldval', $(this).val());
				});
				clearInterval(c);
			}
		},500);
	}

	var menuPrevTimer='';
	var menuNextTimer='';
	let running = false;
	$(document).on('mouseover', '.prev-menu', previous_menu_move);
	$(document).on('click', '.prev-menu', previous_menu_move);
	$(document).on('touchend', '.prev-menu', previous_menu_move);
	function previous_menu_move(e) {
		e.preventDefault();
		$('nav.menu ul ul.tab').stop();
		if (!running) {
			menuPrevTimer = setInterval(function() {
				$('ul li.tablinks ul.tab').prepend($('ul li.tablinks ul.tab > li:last'));
			},200);
		}
		clearInterval(menuNextTimer);
		running = true;
		return false;
	}
	$(document).on('mouseout', '.prev-menu', function() {
		clearInterval(menuPrevTimer);
		running = false;
	});

	$(document).on('mouseover', '.next-menu', next_menu_move);
	$(document).on('click', '.next-menu', next_menu_move);
	$(document).on('touchend', '.next-menu', next_menu_move);
	function next_menu_move(e) {
		e.preventDefault();
		$('nav.menu ul ul.tab').stop();
		if (!running) {
			menuNextTimer = setInterval(function () {
				$('ul li.tablinks ul.tab').append($('ul li.tablinks ul.tab > li:first'));
			}, 200);
		}
		clearInterval(menuPrevTimer);
		running = true;
		return false;
	}
	$(document).on('mouseout', '.next-menu', function() {
		clearInterval(menuNextTimer);
		running = false;
	});

	$(document).on('click', '#admin-left-toggle', function(e) {
		e.preventDefault();
		$("#admin-left, #home-left").toggle({ direction: "left" }, 1000);
		return false;
	});

	$(document).on('mouseover', '#admin-left', function() {
		$("#_after_submit").val('1');
	});
	$(document).on('touchend', '#admin-left', function() {
		$("#_after_submit").val('1');
	});

	$(document).on('click', '.modal-footer button', function(e) {
		update_active_enabled();
	});

	$('#admin-table_wrapper .bottom select, #forms-results-table_wrapper .bottom select')
		.on('change',update_active_enabled);

	function update_active_enabled(){
		if ($("#last-paginated-number").length > 0) {
			// We have a last-paginated-number, which means we want to redirect to this page number when the table is
			// recreated. At moment this happens when we open and close a submission on the home page.
			let table = $('#admin-table').DataTable();
			let info = table.page.info();
			let sort_column = "";
			let sort_direction = "";

			if (table.order() && table.order()[0]) {
				// Previously the table was sorted by a column, so record the sort details.
				sort_column = table.order()[0][0];
				sort_direction = table.order()[0][1];
			}

			let index = info.page; // Get the current page number and record it to DOM. When submission is opened and closed
			// this info still lives in the DOM and we can get this data to recreate the table correctly and navigate
			// to the correct page number.
			$("#last-paginated-number")
				.attr("data-value", (parseInt($("select[name=admin-table_length]").val()) * index))
				.attr("data-page-length", $("select[name=admin-table_length]").val())
				.attr("data-sort-column", sort_column)
				.attr("data-sort-direction", sort_direction);
		}
		$("#feedback_error").hide();
		actually_update_active_enabled();
		let t=0;
		let c = setInterval(function() {
			actually_update_active_enabled();
			if (t>100){
				clearInterval(c);
			}
			t++;
		},1);
	}

	function actually_update_active_enabled(){
		$("#admin-table .edit-button-group, #admin-table .delete-button-group").each(function(){
			$(this).parent().addClass('controls').removeClass('sorting');
		});
		$("#admin-table .fa-eye").each(function(){
			$(this).parent().parent().addClass('text-align-center tiny-col');
		});
		$("#admin-table i, #admin-table svg").each(function(){
			let cell = $(this).parent().text().toLowerCase().replaceAll(/\s/g,'');
			if (cell.match(/^active$/)){
				$(this).parent().addClass('active-status');
			} else if (cell.match(/^inactive$/)){
				$(this).parent().addClass('inactive-status');
			} else if (cell.match(/^enabled$/)){
				$(this).parent().addClass('enabled-status');
			} else if (cell.match(/^disabled$/)){
				$(this).parent().addClass('disabled-status');
			} else if (cell.match(/^pending$/)){
				$(this).parent().addClass('submission-status pending-status')
			} else if (cell.match(/^approved$/)){
				$(this).parent().addClass('submission-status approved-status')
			} else if (cell.match(/^rejected$/)){
				$(this).parent().addClass('submission-status rejected-status')
			} else if (cell.match(/^superceded$/)){
				$(this).parent().addClass('submission-status')
			}
		});
		$(document).focus();
	}

	$(document).ajaxComplete(function(){

		if ($("#search_form").length > 0) {
			// If page has a search form, example Schedule of Rates / TUM Groups
			clearToastNotifications();
			$("#search_form").hide();// Hide Search Form on page Load.
		}

		if ($(".modal").length > 0) {
			if (typeof $(".modal").draggable !== "undefined") {
				$(".modal").draggable({
					cursor: "move",
					handle: ".modal-content",
				});
			}
		}
		if ($("div.error-toast-msg").length > 0) {
			$("div.error-toast-msg").each(function() {
				openErrorToast($(this).text());
				$(this).remove();
			});
		}
		$('form').attr('autocomplete', 'off');
		$('input').attr('autocomplete', 'off');

		if ($(".accordion").length > 0) {
			// If DOM has accordion
			if ($(".accordion.ui-accordion").length === 0) {
				// If Accordion code hasn't run yet, activate the accordion.
				$(".accordion").accordion({
					active: false,
					collapsible: true
				});
			}
		}

		$('#current-forms-results-table').dataTable({
			retrieve: true,
			paging: false,
			info: false,
			searching: false,
			scrollY: '70vh',
			scrollCollapse: true,
			ordering: false
		});

		let sdisplay=0; // Default to first page always
		let pLength=$("select[name=admin-table_length]").val();
		let sOrder=[]; // initially orders by Name (first column) asc
		if ($("#last-paginated-number").length > 0) {
			// If there is a last-paginated-number, that means on this page, we want to remember the last page we looked at
			// so we can easily redirect to that page number.
			sdisplay = parseInt($("#last-paginated-number").attr("data-value"));
			pLength = $("#last-paginated-number").attr("data-page-length");
			let sCol = $("#last-paginated-number").attr("data-sort-column");
			let sDirect = $("#last-paginated-number").attr("data-sort-direction");
			if (sCol && sDirect) {
				// There was an existing sort on table so record it so when table is rebuilt we can run the sort column again.
				sOrder = [parseInt(sCol), sDirect];
			}
		}

		if (pLength == null) {
			pLength = "10";
		}
		$('#admin-table').dataTable({
			destroy: true,
			dom: '<<it><"bottom"lpr>>',
			retrieve: true,
			info: true,
			searching: false,
			scrollCollapse: true,
			order: sOrder,
			"initComplete": function () {
				$("#admin-table,.dataTable thead,#admin-table_wrapper .bottom .dataTables_length").show();
				if (sdisplay > 0 && $(".dataTables_empty").length > 0) {
					// We are trying to open a page that does not exist, so reset back to page 1.
					console.log("Page Number does not exist, reset to page 1");

					// Ensures that headings a columns in datatables are aligned correctly.
					let table = $('#admin-table').wrap('<div class="dataTables_scroll" />').DataTable();
					table.columns.adjust().draw();
				}
			},
			displayStart: sdisplay,
			processing: true,
			serverSide: true,
			ajax: $('#admin-table').attr('data-ajax'),
			pageLength: parseInt(pLength),
			aoColumnDefs: [{
				'bSortable': false,
				'aTargets': ['nosort']
			}]
		});

		$('#admin-table').on('order.dt', update_active_enabled)
			.on('search.dt', update_active_enabled)
			.on('page.dt', update_active_enabled)
			.on('xhr.dt', function () {
				// End of the datatable event
				if ($("a.header-button-active").attr("href") === "/reporting") {
					// The reporting section is being viewed.
					let c = setTimeout(function () {
						if ($('td.dataTables_empty').text() === "No matching records found") {
							// Few milliseconds after datatable loaded, if there are no records found, display no results error
							$("#admin-table_wrapper").replaceWith('<p class="report_no_results"><em>No results were returned for the given filters.</em></p>');
						}
						clearInterval(c);
					}, 50);
				}
			}).DataTable();

		actually_update_active_enabled();

		$("#full_export_to_excel_button").hide();
		if ($("#rigs_selected").prop('checked') === true && $("#rig_search-list li input:checked").length === 1 &&
			$("#admin-table").length > 0 && $(".dataTables_empty").length === 0) {
			// If rigs filter selected and only one rig option is selected, show the Excel Export button if its available.
			$("#full_export_to_excel_button").show();
		}

	});

	$(document).on('change', '#admin_filter input', function() {
		$($).save_filter_data();
		$("#full_export_to_excel_button").hide();// Hide Excel export button whenever filter is updated, Only way
		// to run Export Excel is to submit search form and receive results
	});

	$(document).on('click', '#apply_filter_button', function() {
		$("#full_export_to_excel_button").hide(); // Reset Excel Export Button
	});

	$(document).on('click', '#export_to_pdf_button', function(e) {
		e.preventDefault();
		let id = $("#cancel_button").attr("data-myvalue");
		let url = "/submissions/"+id+".pdf"
		window.open(url, "_blank");
		return false;
	});

	$(document).on('click', '#export_to_excel_button', function(e) {
		e.preventDefault();
		let id = $("#cancel_button").attr("data-myvalue");
		let url = "/submissions/"+id+".xlsx"
		window.open(url, "_blank");
		return false;
	});

	$(document).on('click', '#full_export_to_excel_button', function(e) {
		e.preventDefault();
		// Change the filter form action to download Excel version instead of javascript version
		let action = $("#submission_filters_form").attr("action");
		let updated_action = action.replace("/submissions/search_forms", "/submissions/search_forms.xlsx")
		$("#submission_filters_form").attr("action", updated_action);
		$("#submission_filters_form").submit();// Submit the form
		// Return back to javascript version so web searches can work as normal.
		$("#submission_filters_form").attr("action", action);
		return false;
	});

	$(document).on('click', '#approval-options #cancel_button', function(e) {
		e.preventDefault();
		$("#options-container,#current-form-container").hide();
		$("#filters-container, #forms-container").show();
		return false;
	});

	$(document).on('click', '#approval-options #reject_button', function(e) {
		e.preventDefault();
		let submission_id = this.getAttribute("data-myvalue");
		let reject_reason = $("#reject-reason").val();
		if (reject_reason.length === 0) {
			alert("You must have a reason to reject the form");
			return;
		}
		$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', true).css('color', '#8ca0aa');
		$('#reject_button').prepend("<img alt='loading' class='ajax-load' src='loading.gif'/>&nbsp;");
		let d = {};
		d['authenticity_token'] = window._token;
		d['id'] = submission_id;
		d['reason'] = reject_reason;
		$.ajax({
			type: "GET",
			url: 'submissions/reject',
			data: d,
			success: function (data) {
				// Run /view/submissions/_approve_form.js.erb
			},
			error: function (data) {
				$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', false).css('color', '#fff');
				$('#reject_button img.ajax-load').remove();
				if (data.status === 403 || data.status === 401){
					openForbiddenErrorToast();
				} else {
					openErrorToast(data.responseText);
				}
			},
		});

		return false;
	});

	$(document).on('click', '#approval-options #approve_button', function(e) {
		e.preventDefault();
		let submission_id = this.getAttribute("data-myvalue");
		let comments = $("#reject-reason").val();
		$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', true).css('color', '#8ca0aa');
		$('#approve_button').prepend("<img alt='loading' class='ajax-load' src='loading.gif'/>&nbsp;");
		let d = {};
		d['authenticity_token'] = window._token;
		d['id'] = submission_id;
		d['reason'] = comments;
		$.ajax({
			type: "GET",
			url: 'submissions/approve',
			data: d,
			success: function (data) {
				// Run /view/submissions/_approve_form.js.erb
			},
			error: function (data) {
				$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', false).css('color', '#fff');
				$('#approve_button img.ajax-load').remove();
				if (data.status === 403 || data.status === 401){
					openForbiddenErrorToast();
				} else {
					openErrorToast(data.responseText);
				}
			}
		});

		return false;
	});

	$(document).on('click', '#approval-options #un_approve_button', function(e) {
		e.preventDefault();
		$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', true).css('color', '#8ca0aa');
		$('#un_approve_button').prepend("<img alt='loading' class='ajax-load' src='loading.gif'/>&nbsp;");
		let submission_id = this.getAttribute("data-myvalue");
		let reject_reason = $("#reject-reason").val();
		if (reject_reason != null && reject_reason.length === 0) {
			alert("You must have a reason to reject the form");
			return;
		}
		let d = {};
		d['authenticity_token'] = window._token;
		d['id'] = submission_id;
		d['reason'] = reject_reason;
		$.ajax({
			type: "GET",
			url: 'submissions/un_approve',
			data: d,
			success: function (data) {
				// Run /view/submissions/_approve_form.js.erb
			},
			error: function (data) {
				$('#approve_button, #reject_button, #un_approve_button, #cancel_button').prop('disabled', false).css('color', '#fff');
				$('#approve_button img.ajax-load').remove();
				if (data.status === 403 || data.status === 401){
					openForbiddenErrorToast();
				} else {
					openErrorToast(data.responseText);
				}
			}
		});

		return false;
	});

	$(document).on('change', 'input[type=radio].saved_filter_selected', function() {
		// After choose next pre filled filter
		let filter_ids = [];
		$('#saved-filters div.saved-filter-item div.option_header input:checked').each( function() {
			filter_ids.push($(this).val());
		});

		let d = {};
		d['id'] = $(this).val();
		d['selected_filters'] = filter_ids;
		d['form_identifier'] = $("#selected_form").val();
		$.ajax({
			type: "GET",
			url: $("#select_filters_filters_path").attr('data-url'),
			data: d,
			success: function(attributes) {
				$("#selected_filters").remove();
				$("#saved-filters").append("<div id='selected_filters' "+attributes+"></div>");
			}
		});
	});

	// Change sort order of list of list items with checkboxes
	$.fn.rearrange_checkbox_list = function(list) {
		let origOrder = list.children();
		let i, checked = [],
			unchecked = [],
			sorted_checked = document.createDocumentFragment(),
			sorted_unchecked = document.createDocumentFragment();

		for (i = 0; i < origOrder.length; i++) {
			if (origOrder[i].getElementsByTagName("input")[0].checked) {
				// If item is checked ensure checked boxes appear at top of the list
				checked.push(origOrder[i]);
			} else {
				// If item is not checked ensure checked boxes appear at bottom of the list
				unchecked.push(origOrder[i]);
			}
		}

		// Sort out the checked items in list
		let check = $(checked);
		check.sort(function(a, b) {
			return $(a).find("label").text().toUpperCase().localeCompare($(b).find("label").text().toUpperCase());
		});
		$.each(check, function(idx, itm) { sorted_checked.appendChild(itm); });

		// Sort out the unchecked items in list
		let uncheck = $(unchecked);
		uncheck.sort(function(a, b) {
			return $(a).find("label").text().toUpperCase().localeCompare($(b).find("label").text().toUpperCase());
		});
		$.each(uncheck, function(idx, itm) { sorted_unchecked.appendChild(itm); });

		// Update the list of items with checked items first, then unchecked items.
		list.append(checked).append(sorted_unchecked);
	}

	// Collect filter values from search form and save values to local storage.
	$.fn.save_filter_data = function() {
		console.log("---Saving to Local Storage ---");

		var hash = {}; // Store filter details in a hash, so it can later easily be saved to local storage
		// Keys in the hash are the filter field names and the values are the selected filter values.
		if (hash["generic_search"] === undefined) {
			hash["generic_search"] = '';
		}
		// Store generic search, top left text box, into hash so it can later be saved to local storage
		hash["generic_search"] = $("#generic_search").val();

		$("#admin_filter form .date-field > input, #admin_filter form input[type=text]").each(function () {
			if (!$(this).hasClass("filter-relationship")) {
				// This field is a filter search box, so no need to save its value.

				// Go through each date field and text field in the search filters
				let field_name = ($(this).attr('id'));
				if (hash[field_name] === undefined) {
					// If variable does not exist in hash, initialize it
					hash[field_name] = '';
				}
				// Store the date value in the hash so it can later be saved to local storage
				hash[field_name] = $(this).val();
			}
		});
		$("#admin_filter form input[type=checkbox]").each(function() {
			// Go through all checkboxes in the search filter
			let field_name = ($(this).attr('name').replace('[]',''));
			if (hash[field_name] === undefined) {
				// If variable does not exist in hash, initialize it
				hash[field_name] = [];
			}
			let value = $(this).val(); // Get the value of the checkbox, usually its the id value. If we are in
			// reporting section this is the id in the reporting database. If we are in the primary section, this is the
			// id in the primary database. The id in reporting and id in primary can reference the same data but because
			// both are stored in different databases, the id's between databases do not necessarily match.

			if ($(this).attr("identifier")) {
				// If it has an attribute called identifier, it means this checkbox is in reporting section, and its id
				// $(this).val()
				// is a reference to reporting database id. So we need to use attribute identifier when saving to local storage
				// because its reference to the record in primary. We need to do this, so the id in reporting pages
				// and id in primary pages can match up in regard to saving the id of the filter. This is to keep it
				// consistent between primary and reporting.
				value = $(this).attr("identifier");
			}

			if ($(this).prop('checked')) {
				// Checkbox is checked
				hash[field_name].push(value);
			} else {
				// Checkbox is not checked, so if local storage has value already then we need to remove it
				const index = hash[field_name].indexOf(value);
				if (index > -1) { // only splice array when item is found
					hash[field_name].splice(index, 1); // 2nd parameter means remove one item only
				}
			}
		});

		let keys = Object.keys(hash); // Find all the keys in the hash.
		$(keys).each(function(i) {
			// Go through all keys in the hash, and save data to local storage
			let key = keys[i];
			let values = hash[keys[i]];
			if (values) {
				// A value has been entered in the filter

				// Check if its an array of checkboxes or a text box. If its an array save as a string separated by commas.
				let v = Array.isArray(values) ? values.join(',') : values;
				// Save filtered value to local storage
				localStorage.setItem(key, v);
			} else {
				// The value in the filter is empty so delete any saved local storage value.
				localStorage.setItem(key, '');
			}
		});
	}

	// Filter filter values from local storage, populate search form and filter results on page
	$.fn.load_filter_data = function() {
		// Find generic search filter value from local storage
		let generic_search = localStorage.getItem("generic_search");
		// Update generic search filter with the value from local storage
		$("#generic_search").val(generic_search).keyup();

		$("#admin_filter form .date-field > input, #admin_filter form input[type=text]").each(function() {
			if (!$(this).hasClass("filter-relationship")) {
				// This field is a filter search box, so no need to retrieve its value

				// Go through all date fields and text fields in the search filter

				// Get the id of each date, each id is a key to find last value in local storage
				let field_name = ($(this).attr('id'));

				// Now we have key, get last date value from local storage
				$(this).val(localStorage.getItem(field_name));
			}
		});
		$("#admin_filter form input[type=checkbox]").each(function() {
			// Go through all checkboxes in search filter

			// Get the name field from all checkboxes as this is the key to find the last value in local storage
			let field_name = ($(this).attr('name').replace('[]',''));

			// Now that we have the key, get the value fro local storage
			let values = localStorage.getItem(field_name);

			// Something was found in local storage, so now we need to populate checkboxes
			let v = $(this).val();
			if ($(this).attr('identifier')) {
				// This page has attribute identifier, which means we are in the reporting section of the site
				// Get this value as it is a reference to the primary database.
				v = $(this).attr('identifier');
			}
			// In local storage, convert value into an array and see if this checkbox is in local storage.
			if (values && values.split(',').includes(v)) {
				// Since it is, we need to tick this checkbox.
				$(this).prop("checked", true);
			} else {
				// Not in local storage, so un tick
				$(this).prop("checked", false);
			}

		});

		// After figuring out which filter items need to be checked, go through the lists and order each one individually.
		// Ensure all checked items appear at the top of their lists
		$('.filter-list.children').each(function() {
			$(jQuery).rearrange_checkbox_list($(this));
		});

	}

	$.fn.reset_password = function(url) {
		if (confirm('Reset User Password? \n\n This will send a password reset email to the user.')) {
			$.ajax({
				type: "post",
				url: url,
				success: function () {
					clearToastNotifications();
					openNoticeToast('Done!');
				},
				data: {}
			});
		}
	}

	$.fn.load_saved_filters = function() {
		console.log("Loading Saved Filters");
		if ($("#saved-filters").length > 0) {
			if ($("#selected_filters").length > 0) {
				$("#status_search-list input.filter_checkbox").prop('checked', false);
				console.log("Setting Default Filter");
				// Reset all checkboxes
				$("#filter-list input[type=checkbox]").prop('checked', false);
				let status_selected=false;
				let statuses = [];
				// Reset all text fields
				$(".filter_options input.filter-list, .filter_options .date-field input").val('');
				$.each($("#selected_filters")[0].attributes, function() {
					if (this.name !== "id") {
						console.log(this.name, this.value);
						if (this.name.match(/selected$/)) {
							$("#"+this.name).prop('checked',this.value);
						} else {
							if ($("input."+this.name+"_checkbox[type=checkbox]").length > 0) {
								var n = this.name;
								$.each(this.value.split(","), function() {
									// Load checkbox
									$("input."+n+"_checkbox[type=checkbox][value="+this+"]").prop('checked', true);
								});
							} else if ($("input[name="+this.name+"]").length > 0) {
								// Load text field
								$("input[name="+this.name+"]").val(this.value);
							} else if ($("input[name=date_search\\["+this.name+"\\]]").length > 0) {
								// Load date field
								$("input[name=date_search\\["+this.name+"\\]]").val(this.value);
							}
						}
						if (this.name === "status_selected") {
							status_selected=this.value;
						} else if (this.name === "statuses") {
							statuses=this.value.split(",");
						}
					}
				});
				if (status_selected) {
					$.each(statuses, function() {
						$("#statuses_"+this).prop('checked',true);
					});
				}

				// Now that the default filter values have been set, run the search
				$('#apply_filter_button').click();

			} else {
				console.log("Not loading prefilled filter");

				// is not running any prefilled filter set or even default filter
				// Load search form filter values from local storage
				$(jQuery).load_filter_data();
			}
		} else {
			$(jQuery).load_filter_data();
		}
	}
	if ($("#saved-filters").length > 0) {
		$(jQuery).load_saved_filters();
	}

	$("#admin_content").hide();// Hide content so when we change pages, you see less residue during change over.
	setInterval(function() {
		if ($("#selected_filters").length === 0 && $("#admin_filter").length > 0) {
			// Once admin_filter DOM appears, attempt to load filter data from local cache and submit form.
			if ($("#lock_form_submit").length > 0) {
				$($).load_filter_data();
				$("#_after_submit").val("0");
				$("#apply_filter_button").click();
				$("#lock_form_submit").remove();
				$("#admin_content").show(); // Correct content show now be visible so show results.
			}
		}
		if ($(".run-ajax-complete").length > 0) {
			$(document).triggerHandler('ajaxComplete');
			$(".run-ajax-complete").remove();// Stop current main content from reloading as it should be loaded now.

			if ($('.fa-clipboard').length > 0) {
				// Setup Copy to Clipboard functionality
				let clipboard = new ClipboardJS('.fa-clipboard');

				clipboard.on('success', function (e) {
					clearToastNotifications();
					openNoticeToast("API Copied to Clipboard!");
					e.clearSelection();
				});

				clipboard.on('error', function (e) {
					clearToastNotifications();
					openErrorToast("Failed to copy API to Clipboard!");
				});
			}

			if ($(".modal").length > 0) {
				if (typeof $(".modal").draggable !== "undefined") {
					$(".modal").draggable({
						cursor: "move",
						handle: ".modal-content",
					});
				}
			}

			$("#admin_content").show(); // Correct content show now be visible so show results.
		}
	}, 500);

});